import Container from "@cospex/client/converter/components/Container";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const getIndex = (path: string) => {
  if (path.includes("overview")) {
    return 0;
  }
  if (path.includes("compression")) {
    return 1;
  }
  if (path.includes("account")) {
    return 2;
  }
};

export default function Layout() {
  const navigate = useNavigate();
  const { signout } = useAuth();
  const index = getIndex(window.location.pathname);
  const { t } = useTranslation();
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Container
      sx={{
        pt: 4,
        pb: 8,
      }}
    >
      <Stack direction={md ? "row" : "column"}>
        <Tabs
          value={index}
          aria-label="nav tabs example"
          orientation={md ? "vertical" : "horizontal"}
          sx={{
            mb: {
              md: 0,
              xs: 2,
            },
          }}
        >
          <Tab
            label={t("dashboard-link-overview")}
            value={0}
            onClick={() => {
              navigate("/dashboard/overview");
            }}
          />
          <Tab
            label={t("dashboard-link-compression")}
            value={1}
            onClick={() => {
              navigate("/dashboard/compression");
            }}
          />
          <Tab
            label={t("dashboard-link-account")}
            value={2}
            onClick={() => {
              navigate("/dashboard/account");
            }}
          />
          <Tab
            label={t("dashboard-link-logout")}
            value={3}
            onClick={() => {
              signout();
              navigate("/");
            }}
          />
        </Tabs>
        <Box
          sx={{
            pl: {
              md: 4,
              xs: 0,
            },
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Container>
  );
}
