import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import DefaultConversionPreview from "@cospex/client/converter/components/DefaultConversionPreview";
import PDFConversionPreview from "@cospex/client/converter/components/PDFConversionPreview";
import Pricing from "@cospex/client/converter/components/Pricing";
import Stepper from "@cospex/client/converter/components/Stepper";
import WhyChooseUs from "@cospex/client/converter/components/WhyChooseUs";
import { OPERATION } from "@cospex/client/converter/constants";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import PaymentForm from "@cospex/client/forms/payment";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  ArrowCircleDownRounded,
  FolderOpen,
  Https,
  MarkEmailRead,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { a, useTransition } from "@react-spring/web";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const Payment = () => {
  const { uploads, removeUpload, removeAllUploads } = useUploads();
  const [cards] = useState<number[]>([0, 1, 2]);
  const { t } = useTranslation();

  const email = localStorage.getItem("userEmail");
  const [searchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  const upload = uploads[0];
  const noDetailsError = (!email || !uploads.length) && !identifier;

  const cardTransitions = useTransition(cards, {
    keys: (item) => item,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    trail: 200,
  });

  if (noDetailsError) {
    return (
      <Container
        sx={{
          mb: 2,
        }}
      >
        <Alert severity="error">{t("error-uploading")}</Alert>
      </Container>
    );
  }

  return (
    <>
      <Container sx={{ pb: "3rem" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ "& > div": { height: "100%" } }}>
            {cardTransitions((style, item) =>
              item === 0 ? (
                <a.div style={{ ...style }}>
                  <Card
                    elevation={18}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      boxShadow: "none",
                      borderColor: "primary.main",
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  >
                    <PaymentForm
                      onPaymentSuccessRedirectPath={
                        upload?.operationType === OPERATION.CONVERSION
                          ? "/dashboard/overview"
                          : "/dashboard/compression"
                      }
                      onPaymentSuccess={() => {
                        if (uploads.length > 1) {
                          removeAllUploads();
                          throw new Error(
                            "More than 1 upload found during payment."
                          );
                        }
                        if (upload && upload.realProgress === 100) {
                          removeUpload(upload.id);
                        }
                      }}
                    />
                  </Card>
                </a.div>
              ) : null
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack gap={3}>
              {cardTransitions((style, item) =>
                item === 1 ? (
                  <a.div style={style}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 4,
                      }}
                    >
                      <Stepper />
                      {upload &&
                        (upload.convertTo === "pdf" ? (
                          <PDFConversionPreview upload={upload} />
                        ) : (
                          <DefaultConversionPreview upload={upload} />
                        ))}
                    </Card>
                  </a.div>
                ) : null
              )}
              {cardTransitions((style, item) =>
                item === 2 ? (
                  <a.div style={style}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 4,
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={400}
                        sx={{ mb: 2, textAlign: "center" }}
                      >
                        {t("payment-summary-title")}
                      </Typography>
                      <Box
                        component="ul"
                        sx={{
                          m: 0,
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <li>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <ArrowCircleDownRounded
                              sx={{ color: "primary.main" }}
                            />
                            <Typography variant="body2">
                              {t("payment-receive-list-one")}
                            </Typography>
                          </Stack>
                        </li>
                        <li>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <MarkEmailRead sx={{ color: "primary.main" }} />
                            <Typography variant="body2">
                              {t("payment-receive-list-two")}
                            </Typography>
                          </Stack>
                        </li>
                        <li>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <FolderOpen sx={{ color: "primary.main" }} />
                            <Typography variant="body2">
                              {t("payment-receive-list-three")}
                            </Typography>
                          </Stack>
                        </li>
                        <li>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Https sx={{ color: "primary.main" }} />
                            <Typography variant="body2">
                              {t("payment-receive-list-four")}
                            </Typography>
                          </Stack>
                        </li>
                      </Box>
                    </Card>
                  </a.div>
                ) : null
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          overflow: "hidden",
          py: {
            md: 8,
            xs: 4,
          },
          background:
            "linear-gradient(white 0%, white 50%, #f5f5f5 50%, #f5f5f5 100%)",
        }}
      >
        <Container>
          <ReviewCarousel />
        </Container>
      </Box>
      <Container
        sx={{
          py: 8,
        }}
      >
        <WhyChooseUs />
      </Container>
      <Container
        sx={{
          py: 8,
        }}
      >
        <Pricing />
      </Container>
    </>
  );
};

export default Payment;
