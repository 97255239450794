import PricingComponent from "@cospex/client/converter/components/PricingComponent";
import { Box } from "@mui/material";

export default function Page() {
  return (
    <Box
      sx={{
        py: {
          md: 12,
          sm: 8,
          xs: 6,
        },
      }}
    >
      <PricingComponent />
    </Box>
  );
}
