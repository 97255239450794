import { CONVERSION_META, EXTENSION_TO_COLOR } from "./constants";

export const getAcceptedMimeTypes = () => {
  const mimeTypes = [];
  for (const category in CONVERSION_META) {
    const { mimeTypes: categoryMimeTypes } =
      CONVERSION_META[category as keyof typeof CONVERSION_META];
    mimeTypes.push(...categoryMimeTypes);
  }
  return mimeTypes;
};

export const getConversionTargets = (mimeType: string) => {
  const mainType = mimeType.split("/")[0];
  const allTargets = new Set<string>();

  for (const { mimeTypes, targets } of Object.values(CONVERSION_META)) {
    if (
      mimeTypes.includes(mimeType) ||
      mimeTypes.includes(`${mainType}/*`) ||
      mimeTypes.some((type) => type.startsWith(`${mainType}/`))
    ) {
      targets.forEach((target) => allTargets.add(target));
    }
  }

  return allTargets.size > 0 ? Array.from(allTargets) : null;
};

export const getExtensionColor = (extension: string) =>
  EXTENSION_TO_COLOR[extension as keyof typeof EXTENSION_TO_COLOR] || "black";

export const getOperationFromQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const compressionFormat = urlParams.get("compressionFormat");
  const convertFrom = urlParams.get("from");
  const convertTo = urlParams.get("to");
  return {
    convertFrom: convertFrom || undefined,
    convertTo: convertTo || undefined,
    compressionFormat,
  };
};
