import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { redirect, useParams } from "react-router-dom";

const handleDownload = async (url: string, filename: string) => {
  const response = await axios.get(url, { responseType: "blob" });
  fileDownload(response.data, filename);
};

export default function FileDownload() {
  const { t } = useTranslation();
  const { id } = useParams("/download/:id");
  const [isDownloading, setIsDownloading] = useState(false);

  const query = useQuery(["files", id], async () => {
    const res = await axios.get(`/api/converter/files/${id}`, {
      withCredentials: true,
      headers: { Authorization: "Bearer " + getToken() },
    });
    return res.data;
  });

  const handleDownloadButtonClick = async () => {
    setIsDownloading(true);
    try {
      await handleDownload(query.data.url, query.data.processedName);
      redirect("/dashboard/overview");
    } catch {
      alert("Failed to download the file.");
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (query.isSuccess && query.data) {
      handleDownloadButtonClick();
    }
  }, [query.isSuccess]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "2rem",
          textAlign: "center",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          {query.isLoading && <CircularProgress />}
          {query.isError && (
            <Typography color="error">Error loading file data.</Typography>
          )}
          {query.isSuccess && (
            <Stack sx={{ gap: 3 }}>
              <Typography>{t("download-description")}</Typography>
              <LoadingButton
                variant="contained"
                startIcon={<Download />}
                loadingIndicator="Downloading…"
                loading={isDownloading}
                onClick={handleDownloadButtonClick}
              >
                {t("dashboard-overview-action")}
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Box>
  );
}
