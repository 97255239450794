export const EXTENSION_TO_COLOR = {
  svg: "#FF8827",
  txt: "#538BF9",
  odt: "#2ca02c",
  rtf: "#163ED3",
  png: "#91492D",
  tiff: "#8579D1",
  webp: "#6884AD",
  psd: "#19A7CA",
  webm: "#B54EA7",
  mov: "#192E99",
  m4a: "#2E8E34",
  xls: "#0D743B",
  xlsx: "#0C733A",
  ppt: "#E54E2C",
  pptx: "#dbdb8d",
  xml: "#9edae5",
  epub: "#8c6d31",
  md: "#d6616b",
  mp4: "#91492D",
  wmv: "#ce6dbd",
  mpg: "#de9ed6",
  "3gp": "#6baed6",
  m4v: "#9ecae1",
  mkv: "#2C8939",
  mp3: "#700B0B",
  wav: "#8579D1",
  ogg: "#fdd0a2",
  aac: "#4D2D91",
  ae: "#491885",
  ai: "#F58600",
  aiff: "#7C3F16",
  asf: "#00FFFF",
  avi: "#D34F31",
  bmp: "#81AF69",
  caf: "#42F5D7",
  cds: "#D68A4C",
  cdt: "#99901D",
  css: "#42F56F",
  csv: "#036B0A",
  doc: "#1758BB",
  docx: "#1758BB",
  flac: "#2E8E55",
  flv: "#520560",
  gif: "#B8333E",
  html: "#B8333E",
  ico: "#B84B33",
  jpeg: "#4287F5",
  jpg: "#4287F5",
  key: "#D68A4C",
  mp1: "#FF6D00",
  mp2: "#D66600",
  mpeg: "#065E0A",
  pdf: "#FF0000",
  skt: "#FC9F02",
  tif: "#8579D1",
  wma: "#C9D800",
  word: "#1758BB",
  excel: "#0D743B",
};

export const CONVERSION_META = {
  pdf: {
    mimeTypes: ["application/pdf"],
    targets: [
      "word",
      "excel",
      "docx",
      "doc",
      "xls",
      "xlsx",
      "txt",
      "rtf",
      "jpg",
      "png",
      "bmp",
      "ppt",
      "pptx",
      "xml",
      "webp",
      "tiff",
      "svg",
      "psd",
    ],
  },
  text: {
    mimeTypes: [
      "text/plain",
      "text/html",
      "application/xml",
      "application/json",
      "application/rtf",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    targets: [
      "word",
      "excel",
      "pdf",
      "html",
      "epub",
      "txt",
      "rtf",
      "md",
      "jpg",
      "png",
      "xml",
      "csv",
      "ppt",
      "pptx",
      "svg",
      "css",
    ],
  },
  image: {
    mimeTypes: ["image/*"],
    targets: [
      "jpg",
      "png",
      "gif",
      "tiff",
      "webp",
      "pdf",
      "raw",
      "svg",
      "psd",
      "bmp",
      "ico",
    ],
  },
  video: {
    mimeTypes: ["video/*"],
    targets: [
      "mp4",
      "webm",
      "mov",
      "avi",
      "wmv",
      "mpg",
      "flv",
      "3gp",
      "m4v",
      "mkv",
    ],
  },
  audio: {
    mimeTypes: ["audio/*"],
    targets: ["mp3", "m4a", "aac", "wav", "ogg", "flac", "aiff"],
  },
  sheet: {
    mimeTypes: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      "text/tab-separated-values",
      "text/tsv",
    ],
    targets: ["csv", "xls", "xlsx", "pdf"],
  },
};

export const OPERATION: {
  CONVERSION: OperationType;
  COMPRESSION: OperationType;
} = {
  CONVERSION: "conversion",
  COMPRESSION: "optimization",
};
