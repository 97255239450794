import useTranslation from "@cospex/client/hooks/useTranslation";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Card, Stack, Typography } from "@mui/material";
import { useParallax } from "react-scroll-parallax";

const WhyChooseUs = () => {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        {t("homepage-features-title")}
      </Typography>
      <Stack
        gap={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{
          pt: 6,
          "& .MuiCard-root": {
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            color: "primary.main",
          },
        }}
      >
        <Card
          elevation={6}
          ref={
            useParallax({
              speed: -2,
            }).ref as any
          }
        >
          <VerifiedUserOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-a")}</Typography>
          <Typography variant="body2">
            {t("homepage-features-a-description")}
          </Typography>
        </Card>
        <Card
          elevation={6}
          ref={
            useParallax({
              speed: -4,
            }).ref as any
          }
        >
          <CloudUploadOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-b")}</Typography>
          <Typography variant="body2">
            {t("homepage-features-b-description")}
          </Typography>
        </Card>
        <Card
          elevation={6}
          ref={
            useParallax({
              speed: -6,
            }).ref as any
          }
        >
          <FolderOpenOutlinedIcon />
          <Typography variant="h5">{t("homepage-features-c")}</Typography>
          <Typography variant="body2">
            {t("homepage-features-c-description")}
          </Typography>
        </Card>
      </Stack>
    </>
  );
};

export default WhyChooseUs;
