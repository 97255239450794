import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";

const PDFConversionPreview = ({ upload }: { upload: Upload }) => {
  const { t } = useTranslation();

  const paperStyles = {
    aspectRatio: 1 / 1.414,
    backgroundColor: "white",
    height: "100%",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 1,
    border: "1px solid #e0e0e0",
    p: 1,
  };

  const renderPreviewInner = () => {
    if (upload.baseType === "image") {
      return (
        <Box
          sx={{
            ...paperStyles,
            backgroundImage: `url(${upload.s3Url})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      );
    } else {
      const skeletonStyles = {
        width: "80%",
        height: 5,
        backgroundColor: "#e0e0e0",
        mb: 0.5,
      };

      return (
        <Box sx={paperStyles}>
          <Box sx={skeletonStyles} />
          <Box sx={{ ...skeletonStyles, width: "60%" }} />
          <Box sx={{ ...skeletonStyles, width: "90%" }} />
          <Box sx={skeletonStyles} />
          <Box sx={{ ...skeletonStyles, width: "70%" }} />
          <Box sx={skeletonStyles} />
          <Box sx={{ ...skeletonStyles, width: "20%" }} />
          <Box sx={{ ...skeletonStyles, width: "90%" }} />
          <Box sx={skeletonStyles} />
          <Box sx={{ ...skeletonStyles, width: "40%" }} />
        </Box>
      );
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="h6" fontWeight={400}>
          {t("payment-document-ready")}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Box
          sx={{
            height: 220,
          }}
        >
          {renderPreviewInner()}
        </Box>
      </Stack>
    </>
  );
};

export default PDFConversionPreview;
