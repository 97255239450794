import { API_PATH_UNSUBSCRIBE } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircleOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().min(1, "required").email("invalid-email"),
});

type UnsubscribeData = {
  email: string;
};

const Unsubscribe = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<UnsubscribeData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [unsubscribedSuccessfully, setUnsubscribedSuccessfully] =
    useState(false);

  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async ({ email }: UnsubscribeData) => {
      if (!recaptchaRef.current) {
        throw new Error("reCAPTCHA not loaded");
      }
      const reCaptchaResult = await recaptchaRef.current.executeAsync();
      return axios.post(
        API_PATH_UNSUBSCRIBE,
        {
          formData: {
            email,
            ["g-recaptcha-response"]: reCaptchaResult,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: ({ data: { unsubscribed } }) => {
      if (unsubscribed) {
        setUnsubscribedSuccessfully(true);
      }
    },
    onError(res: any) {
      const error = res.response?.data?.error || res.message;
      setError(error);
    },
  });

  return (
    <Container
      sx={{
        minHeight: "66vh",
        py: 12,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onSubmit={handleSubmit((data: UnsubscribeData) =>
          mutation.mutate(data)
        )}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 800,
            textAlign: "center",
            gap: 2,
          }}
        >
          {unsubscribedSuccessfully ? (
            <Stack
              sx={{
                color: "success.dark",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CheckCircleOutline
                color="success"
                fontSize="large"
                sx={{ mb: 2 }}
              />
              {t("unsubscription-successful")}
            </Stack>
          ) : (
            <>
              <Typography variant="h2">{t("unsubscribe-title")}</Typography>
              <Typography variant="body1">
                {t("unsubscribe-description")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <TextInput
                  sx={{
                    width: "100%",
                    maxWidth: 400,
                  }}
                  name="email"
                  control={control}
                  label={t("unsubscribe-placeholder")}
                  formState={formState}
                />
              </Box>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
                size="invisible"
                style={{
                  margin: 0,
                }}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                disableElevation
                size="large"
              >
                <span>{t("cancel-subscription")}</span>
              </LoadingButton>
              {error && <Alert severity="error">{error}</Alert>}
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default Unsubscribe;
