import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/converter/components/Footer";
import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <>
      <Header
        menu={{
          dashboardHref: "/dashboard/overview",
        }}
      />
      <Outlet />
      <Footer />
    </>
  );
}
