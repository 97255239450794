import { API_PATH_CONTACT } from "@cospex/client/constants";
import ContactGraphic from "@cospex/client/converter/img/contact_graphic.svg";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import ContactFormNumber from "@cospex/client/parcel/components/ContactFormNumber";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircleOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().nonempty("required").email("invalid-email"),
  firstname: z.string().nonempty("required"),
  lastname: z.string().nonempty("required"),
  message: z.string().nonempty("required"),
});

type ContactData = {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
};

interface IContact {
  contactNumbers?: { [key: string]: string };
}

const Contact = ({ contactNumbers }: IContact) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<ContactData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { i18n } = useTranslation();
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async (variables: {
      firstname: string;
      lastname: string;
      email: string;
      message: string;
    }) => {
      const recaptcha = await recaptchaRef.current?.executeAsync();
      return axios.post(
        API_PATH_CONTACT,
        {
          formData: {
            firstname: variables.firstname,
            lastname: variables.lastname,
            email: variables.email,
            message: variables.message,
            "g-recaptcha-response": recaptcha,
          },
          language: i18n.language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setMessageSent(true);
    },
    onError(res: any) {
      const error = res.response?.data?.message || res.message;
      setError(error);
    },
  });

  return (
    <Container
      sx={{
        minHeight: "66vh",
        py: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          direction="column"
        >
          <Typography variant="h2" mb={4}>
            {t("contact-title")}
          </Typography>
          <Box
            component="img"
            src={ContactGraphic}
            sx={{
              width: "100%",
              height: "auto",
              maxWidth: 500,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              p: 4,
              width: "100%",
              height: "100%",
              maxWidth: 450,
            }}
            elevation={8}
          >
            {messageSent ? (
              <Stack
                sx={{
                  color: "success.dark",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CheckCircleOutline />
                {t("contact-message-sent")}
                <br />
                {t("contact-message-wait")}
              </Stack>
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {t("contact-subtitle")}
                </Typography>
                <ContactFormNumber contactNumbers={contactNumbers} />
                <form
                  onSubmit={handleSubmit((data: ContactData) =>
                    mutation.mutate(data)
                  )}
                >
                  <Stack spacing={2} sx={{ width: "100%" }} mt={2}>
                    <TextInput
                      name="email"
                      fullWidth
                      label={t("contact-email")}
                      control={control}
                      formState={formState}
                    />
                    <TextInput
                      name="firstname"
                      fullWidth
                      label={t("contact-firstname")}
                      control={control}
                      formState={formState}
                    />
                    <TextInput
                      name="lastname"
                      fullWidth
                      label={t("contact-lastname")}
                      control={control}
                      formState={formState}
                    />

                    <TextInput
                      fullWidth
                      name="message"
                      label={t("contact-message")}
                      control={control}
                      formState={formState}
                      multiline
                      rows={4}
                    />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
                      size="invisible"
                      style={{
                        margin: 0,
                      }}
                    />
                    <LoadingButton
                      type="submit"
                      loading={mutation.isLoading}
                      variant="contained"
                      disableElevation
                    >
                      <span>{t("contact-message-send")}</span>
                    </LoadingButton>
                    {error && <Alert severity="error">{error}</Alert>}
                  </Stack>
                </form>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
