import Contact from "@cospex/client/converter/components/Contact";

export default function ContactPage() {
  return (
    <Contact
      contactNumbers={{
        fr: "01 84 80 81 41",
      }}
    />
  );
}
